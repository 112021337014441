// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-contact-index-js": () => import("./../../../src/templates/pageContact/index.js" /* webpackChunkName: "component---src-templates-page-contact-index-js" */),
  "component---src-templates-pages-index-js": () => import("./../../../src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-pages-no-side-bar-index-js": () => import("./../../../src/templates/pagesNoSideBar/index.js" /* webpackChunkName: "component---src-templates-pages-no-side-bar-index-js" */),
  "component---src-templates-posts-index-js": () => import("./../../../src/templates/posts/index.js" /* webpackChunkName: "component---src-templates-posts-index-js" */)
}

